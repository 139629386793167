import React from 'react';
import { graphql } from "gatsby";
import Layout from "../components/layout"
import { color } from "../components/defaults/styles/theme"
import GeneralFooter from '../components/general_footer';
import RearviewPageComponent from '../components/rearview_page_component';



const RearviewPage = ({data, location, pageContext}) => {
  const seo = {
    frontmatter: {
      title: data.airtable.data.rearview_title,
      slug: `rearview/${data.airtable.recordId}`
    },
  }
  
  
  return (
    <Layout dark seo={seo}>
      
      <RearviewPageComponent
        data={data.airtable.data}
        recordId={data.airtable.recordId}
        location={location}
        pageContext={pageContext}
      />

      <GeneralFooter theme={color.main_dark} />
    </Layout>
  )
}

export default RearviewPage;

export const query = graphql`
        query RearviewEl($id: String!) {
          airtable(id: { eq: $id }, table: { eq: "rearview" }) {
            id
            recordId
            data {
              rearview_link
              rearview_tags
              rearview_title
              rearview_release
              rearview_people {
                id
                recordId
                data {
                  name
                  profession
                  include
                  consent
                  partner_company {
                    data {
                      name
                    }
                  }
                  custom_company
                  startup_company {
                    data {
                      startup
                    }
                  }
                }
              }
              rearview_timestamp
              rearview_description {
                childMarkdownRemark {
                  excerpt(format: HTML, pruneLength: 240)
                  html
                }
              }
              rearview_agenda {
                recordId
                data {
                  agenda_publish
                  agenda_start_time
                  agenda_title
                }
              }
              rearview_partners {
                id
                recordId
                data {
                  active
                  name
                }
              }
              rearview_startups {
                id
                recordId
                data {
                  include
                  startup
                }
              }
              rearview_thumbnail {
                localFiles {
                  childImageSharp {
                    fluid(grayscale: true, quality: 60, maxWidth: 900) {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      `
